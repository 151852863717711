import $ from 'jquery';
import 'lightgallery';
import 'lg-fullscreen';
import 'lg-pager';
import 'lg-thumbnail';
import 'lg-video';
import 'lg-zoom';
import { Application } from 'stimulus';
import ModalController from 'javascripts/stimulus/controllers/modal-controller';
import 'lightgallery/src/sass/lightgallery.scss';
import 'scss/gallery.scss';
import LocalTime from 'local-time';

LocalTime.start();

const application = Application.start();
application.register('modal', ModalController);

export default (function () {
  $(() => {
    const lightGallery = $('#gridImageContainer');
    lightGallery.on('onCloseAfter.lg', () => {
      const lgd = lightGallery.data('lightGallery');
      if (lgd) {
        lgd.destroy(true);
      }
    });

    $('#gridImageContainer').on('click', '.cc-grid-image', function () {
      const index = $('#gridImageContainer .cc-grid-item').index(
        $(this).parent('.cc-grid-item'),
      );
      const images = $('#gridImageContainer')
        .find('.cc-grid-image')
        .map((_idx, image) => {
          const $image = $(image);

          const videoId = $image.data('video-id');
          if (videoId) {
            return {
              thumb: $image.data('thumb'),
              poster: $image.data('thumb'),
              html: ['#video', videoId].join('-'),
            };
          } else {
            return {
              thumb: $image.data('thumb'),
              src: $image.data('full'),
            };
          }
        });
      lightGallery.lightGallery({
        dynamic: true,
        dynamicEl: images,
        index,
        videojs: true,
      });
    });
  });
})();
